label {
    font-weight: 600 !important;
}

.form-wrapper{
    margin: 5%;
}
.card-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 80vh;
    margin: auto;
    .ant-card-body{
      width: 80vw;
      border-radius: 0.375rem;
    }
}

.ant-card.ant-card-bordered {
  border-radius: 0.375rem;
  box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  background-color: #fff;
}

.card-wrapper-confetti{
  display: flex;
  justify-content: center;
  align-items: baseline;
  min-height: 80vh;
  margin: auto;
}
