@import '~antd/dist/antd.css';

body{
    /* background: rgb(72, 155, 223); */
    background: #edf2f9;
}


.yeti{
    background: rgb(72, 155, 223);
}